/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

/* global Power1 TimelineMax Quad ScrollMagic window TweenMax document jQuery */

(function($) {
  const animationBreakpoint = 960; // see matching breakpoint in global/effects/animations.scss

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  const Base = {
    // All pages
    common: {
      init: function() {
        // Static Animations, they will run once the site is loaded
        const home = new TimelineMax();
        home
          .to('.topper .container', 0, { opacity: 1 }, 0)
          .to('.overlay', 0.5, { opacity: 0.4, ease: Quad.easeInOut })
          .staggerFrom(
            '.topper h6, .topper h1, .topper .deck',
            0.35,
            { opacity: 0, yPercent: 3, ease: Quad.easeInOut },
            0.18,
          )
          .staggerFrom(
            '.topper .video-link, .topper .topper-cta a, .topper .donation',
            0.35,
            { opacity: 0, scale: 1.1, ease: Quad.easeInOut },
          )
          .staggerFrom('.topper form', 0.35, {
            opacity: 0,
            scale: 1.1,
            ease: Quad.easeInOut,
          })
          .staggerFrom('.topper .read-more', 0.35, {
            opacity: 0,
            yPercent: -15,
            scale: 1.05,
            ease: Quad.easeInOut,
          })
          .staggerTo(
            '.grid .col-single',
            0.5,
            {
              opacity: 1,
              transform: 'translate3d(0, 0, 0)',
              ease: Quad.easeInOut,
            },
            0.3,
          );
        home.play();

        // SCROLL MAGIC STARTS
        const controller = new ScrollMagic.Controller();

        if ($(window).width() >= animationBreakpoint) {
          // Fade in by sequence: see global/effects/animations.scss, where
          // issues-grid/news-items are hidden by default
          new ScrollMagic.Scene({
            triggerElement: '.issues-grid',
            reverse: false,
            offset: 0,
            triggerHook: 1,
          })
            .setTween(
              TweenMax.staggerTo(
                '.issues-grid .single-issue',
                0.25,
                { opacity: 1 },
                0.15,
              ),
            )
            // .addIndicators()
            .addTo(controller);

          new ScrollMagic.Scene({
            triggerElement: '.news-items',
            reverse: false,
            offset: 0,
            triggerHook: 1,
          })
            .setTween(
              TweenMax.staggerTo(
                '.news-items .news-item',
                0.25,
                { opacity: 1 },
                0.15,
              ),
            )
            // .addIndicators()
            .addTo(controller);

          const cta = new TimelineMax();
          cta
            .staggerFrom(
              '.bottom-cta h6, .bottom-cta h1',
              0.35,
              { opacity: 0, yPercent: 3, ease: Power1.easeInOut },
              0.2,
            )
            .staggerFrom('.bottom-cta form', 0.45, {
              opacity: 0,
              scale: 1.08,
              ease: Power1.easeInOut,
            });

          new ScrollMagic.Scene({
            triggerElement: '.bottom-cta .container',
            reverse: false,
            offset: 100,
            triggerHook: 1,
          })
            .setTween(cta)
            // .addIndicators()
            .addTo(controller);
        }
      },
    },
    // Home page
    home: {
      init: function() {},
    },
    videos: {
      init: function() {},
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  const UTIL = {
    fire: function(func, funcname, args) {
      const namespace = Base;
      funcname = funcname === undefined ? 'init' : funcname;
      if (
        func !== '' &&
        namespace[func] &&
        typeof namespace[func][funcname] === 'function'
      ) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      UTIL.fire('common');

      // eslint-disable-next-line
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(
        i,
        classnm,
      ) {
        UTIL.fire(classnm);
      });
    },
  };

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
